
import { defineComponent, onMounted, onUnmounted, reactive, Ref, ref, watch } from 'vue';

import SVG from '@/components/SVG.vue';
import Input from '@/components/form/Input.vue';
import ColorPicker from '@/components/form/ColorPicker.vue';
import Loader from '@/components/Loader.vue';
import { CreateTaskStatus } from '@/models/task-status.model';
import TaskStatusService from '@/services/task-status.service';
import Toast from '@/toast';

export default defineComponent({
	props: ['projectId', 'active'],

	emits: ['close'],

	components: {
		SVG,
		Input,
		ColorPicker,
		Loader,
	},

	setup(props, ctx) {
		const container: Ref = ref(null);

		const loading = ref(false);

		const form: CreateTaskStatus = reactive({
			title: '',
			color: '',
			project_id: 0,
		});

		onMounted(() => {
			window.onkeydown = function (e: KeyboardEvent) {
				if (e.ctrlKey && e.key === 's') {
					e.preventDefault();

					save();
				}
			};
		});

		onUnmounted(() => {
			window.onbeforeunload = null;
		});

		// Watch active state
		watch(
			() => props.active,
			(value) => {
				// Save when closing the modal
				if (!value) {
					form.title = '';
					form.color = '';
					form.project_id = 0;
					return;
				}

				form.project_id = props.projectId;

				const input = document.getElementById('titleInput');

				if (input) {
					input.focus();
				}
			}
		);

		// save the changes to the task
		async function save() {
			if (props.projectId && form.title.length > 0 && form.color.length > 0 && loading.value === false) {
				loading.value = true;

				await TaskStatusService.create(form)
					.then(() => {
						// Close the overlay
						ctx.emit('close');

						Toast.success('Status lagt afturat', 'Tað eydnaðist at stovna status');
					})
					.finally(() => {
						loading.value = false;
					});
			}
		}

		// Scroll to the top when opening the overlay
		watch(
			() => props.active,
			() => {
				container.value.scrollTo(0, 0);
			}
		);

		return {
			// data
			container,
			form,
			loading,

			// functions
			close,
			save,
		};
	},
});
