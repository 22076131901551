
import { defineComponent, onMounted, onUnmounted, reactive, Ref, ref, watch } from 'vue';

import SVG from '@/components/SVG.vue';
import Input from '@/components/form/Input.vue';
import ColorPicker from '@/components/form/ColorPicker.vue';
import Loader from '@/components/Loader.vue';
import { UpdateTaskStatus } from '@/models/task-status.model';
import TaskStatusService from '@/services/task-status.service';
import Toast from '@/toast';

export default defineComponent({
	props: ['status', 'active'],

	emits: ['close'],

	components: {
		SVG,
		Input,
		ColorPicker,
		Loader,
	},

	setup(props, ctx) {
		const container: Ref = ref(null);

		const loading = ref(false);

		const form: UpdateTaskStatus = reactive({
			title: '',
			color: '',
		});

		onMounted(() => {
			window.onkeydown = function (e: KeyboardEvent) {
				if (e.ctrlKey && e.key === 's') {
					e.preventDefault();

					save();
				}
			};
		});

		onUnmounted(() => {
			window.onbeforeunload = null;
		});

		// Watch active state
		watch(
			() => props.active,
			(value) => {
				// Save when closing the modal
				if (!value) {
					form.title = '';
					form.color = '';
					return;
				}

				form.title = props.status.title;
				form.color = props.status.color;

				const input = document.getElementById('titleInput');

				if (input) {
					input.focus();
				}
			}
		);

		// save the changes to the task
		async function save() {
			if (props.status.id && form.title.length > 0 && form.color.length > 0 && loading.value === false) {
				loading.value = true;

				await TaskStatusService.update(props.status.id, form)
					.then(() => {
						// Close the overlay
						ctx.emit('close');

						form.title = '';
						form.color = '';

						Toast.success('Status lagt afturat', 'Tað eydnaðist at stovna status');
					})
					.finally(() => {
						loading.value = false;
					});
			}
		}

		// Scroll to the top when opening the overlay
		watch(
			() => props.active,
			() => {
				container.value.scrollTo(0, 0);
			}
		);

		return {
			// data
			container,
			form,
			loading,

			// functions
			close,
			save,
		};
	},
});
