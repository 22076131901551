
import { defineComponent, getCurrentInstance } from 'vue';

export default defineComponent({
	props: ['modelValue', 'type', 'label', 'placeholder', 'borderless', 'required', 'disabled', 'blur'],

	setup() {
		return {
			uid: getCurrentInstance()?.uid || '',
		};
	},
});
