import { toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-4" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["value", "id", "required", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("label", {
      for: `input-${_ctx.uid}`,
      class: "block mb-[6px] text-gray-700 text-sm font-semibold"
    }, _toDisplayString(_ctx.label), 9, _hoisted_2), [
      [_vShow, _ctx.label]
    ]),
    _createElementVNode("input", {
      type: "color",
      value: _ctx.modelValue,
      id: `input-${_ctx.uid}`,
      required: _ctx.required,
      disabled: _ctx.disabled,
      onBlur: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.blur && _ctx.blur(...args))),
      style: {"min-height":"2.4rem"},
      onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
      class: "outline-none transition bg-white border border-dark-100 rounded-md"
    }, null, 40, _hoisted_3)
  ]))
}