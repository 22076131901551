import { toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["value", "id", "placeholder", "required", "disabled"]
const _hoisted_3 = ["type", "value", "id", "placeholder", "required", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ 'mb-4': !_ctx.borderless })
  }, [
    _withDirectives(_createElementVNode("label", {
      for: `input-${_ctx.uid}`,
      class: "block mb-[6px] text-gray-700 text-sm font-semibold"
    }, _toDisplayString(_ctx.label), 9, _hoisted_1), [
      [_vShow, _ctx.label]
    ]),
    (_ctx.type === 'textarea')
      ? (_openBlock(), _createElementBlock("textarea", {
          key: 0,
          value: _ctx.modelValue,
          id: `input-${_ctx.uid}`,
          placeholder: _ctx.placeholder,
          required: _ctx.required,
          disabled: _ctx.disabled,
          onBlur: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.blur && _ctx.blur(...args))),
          onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
          class: "py-2 px-3 w-full h-32 bg-white text-sm border border-[#e2e2e2] rounded-md outline-none transition resize-none"
        }, null, 40, _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.type !== 'textarea')
      ? (_openBlock(), _createElementBlock("input", {
          key: 1,
          type: _ctx.type || 'text',
          value: _ctx.modelValue,
          id: `input-${_ctx.uid}`,
          placeholder: _ctx.placeholder,
          required: _ctx.required,
          disabled: _ctx.disabled,
          onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.blur && _ctx.blur(...args))),
          onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
          class: _normalizeClass(["py-2 px-3 text-sm w-full outline-none transition", { 'bg-white border border-dark-100 rounded-md': !_ctx.borderless, 'bg-transparent': _ctx.borderless }])
        }, null, 42, _hoisted_3))
      : _createCommentVNode("", true)
  ], 2))
}