import { toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toHandlers as _toHandlers, mergeProps as _mergeProps, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock, withCtx as _withCtx, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = { class: "group relative" }
const _hoisted_3 = ["id", "value", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SVG = _resolveComponent("SVG")!
  const _component_DatePicker = _resolveComponent("DatePicker")!
  const _directive_click_away = _resolveDirective("click-away")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["group", { 'mb-4': !_ctx.borderless }])
  }, [
    _withDirectives(_createElementVNode("label", {
      for: `input-${_ctx.uid}`,
      class: "block mb-1 text-gray-700 text-sm font-semibold"
    }, _toDisplayString(_ctx.label), 9, _hoisted_1), [
      [_vShow, _ctx.label]
    ]),
    _createVNode(_component_DatePicker, {
      modelValue: _ctx.date,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.date) = $event)),
      popover: { visibility: 'focus' },
      mode: _ctx.isDateTime ? 'dateTime' : 'date',
      "min-date": _ctx.min,
      "max-date": _ctx.max,
      "minute-increment": 15,
      is24hr: ""
    }, {
      default: _withCtx(({ inputEvents }) => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("input", _mergeProps({
            id: _ctx.uid,
            value: _ctx.formattedDate,
            onKeydown: _cache[0] || (_cache[0] = ($event: any) => {
							if ($event.key == 'Escape' || $event.key == 'Backspace' || $event.key == 'Delete')
								_ctx.date = null;
						})
          }, _toHandlers(inputEvents), {
            class: ["py-2 px-3 w-full rounded-md outline-none", { 'bg-transparent': _ctx.borderless, 'bg-gray-200 rounded-none': _ctx.disabled }],
            disabled: _ctx.disabled
          }), null, 16, _hoisted_3),
          (!_ctx.noIcon && !_ctx.date)
            ? (_openBlock(), _createBlock(_component_SVG, {
                key: 0,
                name: "icons/calendar",
                class: "absolute top-1/2 right-3 -translate-y-1/2 w-4 h-4 text-gray-700 pointer-events-none"
              }))
            : _createCommentVNode("", true),
          (!_ctx.noIcon && _ctx.date)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                href: "#",
                onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.date = null), ["prevent"])),
                class: "cursor-pointer"
              }, [
                _createVNode(_component_SVG, {
                  name: "icons/add",
                  class: "absolute top-1/2 right-3 -translate-y-1/2 rotate-45 w-4 h-4 text-gray-400 hover:text-gray-800 transition"
                })
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "mode", "min-date", "max-date"])
  ], 2)), [
    [_directive_click_away, () => (_ctx.active = false)]
  ])
}