
import { defineComponent, onMounted, Ref, ref } from 'vue';
import _sortBy from 'lodash/sortBy';
import { useRoute } from 'vue-router';

import Store from '@/store';

import useEmitter from '@/compostables/useEmitter';

import Layout from '@/components/Layout.vue';
import Loader from '@/components/Loader.vue';
import EditTaskStatusOverlay from '@/components/project/status/EditOverlay.vue';
import AddTaskStatusOverlay from '@/components/project/status/AddOverlay.vue';
import Group from '@/components/project/tasks/Group.vue';
import TaskStatus from '@/models/task-status.model';
import Table from '@/components/draggable-table/Table.vue';
import TaskStatusService from '@/services/task-status.service';

import SVG from '@/components/SVG.vue';
import Toast from '@/toast';

export default defineComponent({
	components: {
		Layout,
		Loader,
		Table,
		EditTaskStatusOverlay,
		AddTaskStatusOverlay,
		Group,
		SVG,
	},

	setup() {
		const container: Ref = ref(null);

		const route = useRoute();
		const emitter = useEmitter();

		const statuses: Ref<TaskStatus[]> = ref([]);

		const activeStatus = ref<TaskStatus>();

		const loading = ref(true);
		const dragLoader = ref(false);

		const addOverlay = ref(false);

		// On Mounted
		onMounted(async () => {
			await fetchStatus();

			Store.set('header.options', [
				{
					title: 'Stovna status',
					action: 'status:add',
				},
			]);

			loading.value = false;

			initSortable();

			// Open Task overlay
			emitter.on('status:add', () => {
				addOverlay.value = true;
			});

			// Open Task overlay
			window.onkeydown = function (e: KeyboardEvent) {
				if (e.key === 'Escape') {
					addOverlay.value = false;
					activeStatus.value = undefined;
				}
			};
		});

		// Functions

		// Open the TaskStatus overlay
		function openOverlay(status: TaskStatus) {
			const user = Store.get('user');

			if (!user.value.can('update statuses')) {
				return;
			}

			activeStatus.value = status;
		}

		async function initSortable(): Promise<void> {
			if (!container.value) {
				return;
			}

			const Sortable = (await import('sortablejs')).default;

			new Sortable(container.value, {
				handle: '.handle',
				animation: 175,
				draggable: '.draggable',

				async onEnd(e) {
					dragLoader.value = true;

					arrayMove(statuses.value, e.oldIndex || 0, e.newIndex || 0);

					await TaskStatusService.bulkUpdateOrder(statuses.value).finally(() => {
						dragLoader.value = false;
					});
				},
			});
		}

		function arrayMove(array: any[], from: number, to: number): void {
			let element = array[from];

			array.splice(from, 1);
			array.splice(to, 0, element);
		}

		function closeAddModal() {
			addOverlay.value = false;

			fetchStatus();
		}

		function closeEditModal() {
			activeStatus.value = undefined;

			fetchStatus();
		}

		async function fetchStatus() {
			await TaskStatusService.index(Number(route.params.id)).then((response) => {
				if (response) {
					statuses.value = response;
				}
			});
		}

		function shouldDelete(status: TaskStatus) {
			const user = Store.get('user');

			if (!user.value.can('delete statuses')) {
				return;
			}

			if (confirm('Vilt tú strika status?')) {
				TaskStatusService.delete(status.id).then(() => {
					fetchStatus();
				});
			}
		}

		return {
			// data
			loading,
			activeStatus,
			addOverlay,
			statuses,
			container,
			dragLoader,

			// functions
			openOverlay,
			closeAddModal,
			closeEditModal,
			shouldDelete,
		};
	},
});
