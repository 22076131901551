
import { computed, defineComponent, getCurrentInstance, onMounted, ref, watch } from 'vue';
import { DatePicker } from 'v-calendar';

import SVG from '@/components/SVG.vue';

export default defineComponent({
	props: ['modelValue', 'label', 'min', 'max', 'isDateTime', 'borderless', 'no-icon', 'disabled'],

	components: {
		SVG,
		DatePicker,
	},

	setup(props, ctx) {
		const active = ref(false);
		const date = ref(null as Date | null);
		const uid = ref(getCurrentInstance()?.uid || '');

		// computed
		const formattedDate = computed(() => {
			if (!date.value) {
				return '';
			}

			if (props.isDateTime) {
				return date.value?.format('%-d. %mn3. %y kl. %H:%M');
			}

			return date.value?.format('%-d. %mn3. %y');
		});

		// set the date value to the modelValue
		onMounted(() => {
			date.value = props.modelValue;
		});

		// change the date on modelValue change
		watch(
			() => props.modelValue,
			(value) => {
				date.value = value;
			}
		);

		// update the modelValue when date is changed
		watch(date, (value) => {
			ctx.emit('update:modelValue', value);

			active.value = false;
		});

		return {
			// data
			uid,
			active,
			date,
			formattedDate,
		};
	},
});
